import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { isEqual, get } from '../../lib/nodash';
import { Box, Button, Text, List } from 'grommet';
import { format, parseISO } from 'date-fns';

import Loading from '../Loading';
import { GET_ORDER_RETURN } from '../../queries/returnQueries';
import formatCurrency from '../../lib/formatCurrency';

const OrderReturn = ({ id, status, returnType, ...rest }) => {
  const { data, loading, startPolling, stopPolling } = useQuery(
    GET_ORDER_RETURN,
    {
      variables: { id },
      fetchPolicy: 'network-only',
      pollInterval: 1000,
    }
  );
  const orderReturn = get('orderReturn', data) || status;

  useEffect(() => {
    if (get('orderReturn.shipping_label.pdf_url', data)) {
      stopPolling();
    } else {
      startPolling(1000);
    }
  }, [data]);

  return (
    <Box {...rest}>
      {loading ? (
        <Box fill justify="center" align="center">
          <Loading size="large" />
        </Box>
      ) : (
        <Box direction="row-responsive" gap="medium" justify="between">
          <Box gap="small">
            {get('created_at', orderReturn) && (
              <Text size="small" color="dark-4">
                Created on{' '}
                {format(parseISO(get('created_at', orderReturn)), 'M/d/yy')}
              </Text>
            )}
            {get('refund_amount', orderReturn) && (
              <Text size="small">
                Refund amount:{' '}
                {formatCurrency(get('refund_amount', orderReturn))}
              </Text>
            )}
            <List
              pad="small"
              border={false}
              data={get('order_return_items', orderReturn)}
            >
              {(datum) => (
                <Box direction="row">
                  {datum.quantity}x {datum.title}
                </Box>
              )}
            </List>
          </Box>
          {isEqual('label_created', get('status', orderReturn)) && (
            <Box gap="small">
              <Text>
                Your return is in our system and we are waiting to receive it.
              </Text>
              <Button
                href={`${get(
                  'shipping_label.pdf_url',
                  orderReturn
                )}?download=0`}
                label="Download Shipping Label"
                target="_blank"
                primary
              />
              {returnType === 'exchange' && (
                <Button
                  label="Shop for exchange products"
                  href="https://backdrophome.com"
                />
              )}
            </Box>
          )}
          {isEqual('requested', get('status', orderReturn)) && (
            <Box
              direction="row"
              align="center"
              gap="small"
              margin={{ vertical: 'small' }}
            >
              <Loading />
              <Text margin="none">
                We&apos;re creating a return label for your order...
              </Text>
            </Box>
          )}
          {isEqual('completed', get('status', orderReturn)) && (
            <Box
              direction="row"
              align="center"
              gap="small"
              margin={{ vertical: 'small' }}
            >
              <Text margin="none">
                We have processed your return and refunded you{' '}
                {formatCurrency(get('refund_amount', orderReturn))}
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

OrderReturn.propTypes = {
  id: PropTypes.number.isRequired,
  returnType: PropTypes.oneOf(['return', 'exchange']),
  status: PropTypes.oneOf([
    'pending',
    'requested',
    'label_created',
    'in_progress',
    'completed',
    'declined',
  ]),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
};

export default OrderReturn;
