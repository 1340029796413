import React from 'react';
import PropTypes from 'prop-types';
import { get, sortBy, reverse, isEqual } from '../../lib/nodash';
import { useQuery } from '@apollo/client';
import { Box, Button } from 'grommet';
import { useStaticQuery, graphql } from 'gatsby';
import { useStoryblokState } from '../../lib/storyblok';

import OrderReturn from './OrderReturn';
import usePageBodyComponents from '../DynamicPage/usePageBodyComponents';
import { GET_ORDER_RETURNS } from '../../queries/returnQueries';

const OrderReturns = ({
  orderNumber,
  email,
  onReturnMore,
  returnType,
  location,
}) => {
  const { data } = useQuery(GET_ORDER_RETURNS, {
    variables: { order_number: orderNumber, email },
    fetchPolicy: 'network-only',
  });

  const { storyblokEntry } = useStaticQuery(graphql`
    query ReturnsResultPageContent {
      storyblokEntry(slug: { eq: "order-return-success" }) {
        id
        name
        full_slug
        content
        field_title_string
        internalId
      }
    }
  `);
  const resultComponents = usePageBodyComponents(
    useStoryblokState(storyblokEntry),
    location
  );

  return (
    <Box gap="large">
      {resultComponents}
      {reverse(sortBy('created_at', get('lookupOrderReturns', data) || [])).map(
        (x) => (
          <OrderReturn
            key={x.id}
            status={x.status}
            returnType={x.returnType}
            id={x.id}
            border={{ side: 'all', size: 'small' }}
            pad="medium"
          />
        )
      )}
      <Box margin={{ top: 'medium' }} direction="row-responsive" gap="small">
        <Button secondary label="Return more items" onClick={onReturnMore} />
        {isEqual('exchange', returnType) && (
          <Button
            primary
            label="Shop for your exchange items"
            href="https://www.backdrophome.com"
          />
        )}
      </Box>
    </Box>
  );
};

OrderReturns.propTypes = {
  orderNumber: PropTypes.string,
  email: PropTypes.string,
  onReturnMore: PropTypes.func.isRequired,
  location: PropTypes.object,
  returnType: PropTypes.string,
};

export default OrderReturns;
